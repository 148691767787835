
import Vue, { VueConstructor } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { TsxassAbility, LicenseType } from '@/helpers/constants';
import errorHandler from '@/helpers/errorHandler';
import { tsxassApi } from '@/services/api';
import { V1EntitiesAnalyticsSurveysTabsTab } from '@/services/api/tsxass';
import AnalyticsPageTitle from '@/components/analytics/feedback/AnalyticsPageTitle.vue';
import NoDataStub from '@/components/common/NoDataStub.vue';
import PdfExportDialog from '@/components/analytics/pdf/PdfExportDialog.vue';
import BackButton from '@/layouts/app/BackButton.vue';
import TabDestructors from './TabDestructors.vue';
import TabBaseCompetencies from './TabBaseCompetencies.vue';
import TabAdditionalQuestions from './TabAdditionalQuestions.vue';
import TabOpenQuestions from './TabOpenQuestions.vue';
import TabDevelopmentTasks from './TabDevelopmentTasks.vue';
import TabNps from './TabNps.vue';
import ExportEmployeeAnalyticsToExcel from './ExportEmployeeAnalyticsToExcel.vue';
import NavigateToYandexGPT from './NavigateToYandexGPT.vue';

enum TabAliases {
  GENERAL = 'general',
  EXTRA = 'extra',
  OPEN = 'open',
  DESTRUCTOR = 'destructor',
  DEVELOPMENT_TASKS = 'development_tasks',
  NPS = 'nps',
}

interface TabItem {
  name: string;
  component: any;
  alias: TabAliases;
}

const TAB_COMPONENTS: Record<TabAliases, VueConstructor<any>> = {
  [TabAliases.GENERAL]: TabBaseCompetencies,
  [TabAliases.EXTRA]: TabAdditionalQuestions,
  [TabAliases.OPEN]: TabOpenQuestions,
  [TabAliases.DESTRUCTOR]: TabDestructors,
  [TabAliases.DEVELOPMENT_TASKS]: TabDevelopmentTasks,
  [TabAliases.NPS]: TabNps,
};

export default Vue.extend({
  name: 'SurveyAnalytics',

  components: {
    AnalyticsPageTitle,
    NoDataStub,
    PdfExportDialog,
    ExportEmployeeAnalyticsToExcel,
    BackButton,
    NavigateToYandexGPT,
  },

  inject: ['RouteNames'],

  data() {
    return {
      currentTab: null,
      visibleTabs: [] as V1EntitiesAnalyticsSurveysTabsTab[],
      tabsLoading: false,
      isShowPdfExportDialog: false,
    };
  },

  computed: {
    ...mapState('profile', {
      licenses: 'licenses',
    }),
    ...mapState('engagementInfo', {
      user: 'user',
    }),
    ...mapGetters('engagementInfo', {
      can: 'can',
    }),
    backTo() {
      return this.$route.query?.userId ? '/app/user_analytics/employee/pivot_table ' : '/app/user_analytics/me';
    },
    tabs(): TabItem[] {
      const tabs = [];

      tabs.push(...this.visibleTabs.map((tab) => ({
        name: tab.name,
        alias: tab.alias as TabAliases,
        component: TAB_COMPONENTS[tab.alias as TabAliases],
      })));

      // добавляем таб ИПР в конец, если есть лицензия
      if (this.has360DevelopmentPlanLicense) {
        tabs.push({
          name: this.$t('analytics.developmentTasks.tabName') as string,
          alias: TabAliases.DEVELOPMENT_TASKS,
          component: TAB_COMPONENTS[TabAliases.DEVELOPMENT_TASKS],
        });
      }
      return tabs;
    },
    surveyId(): number {
      return Number(this.$route.params.surveyId) || 0;
    },
    userId(): string | undefined {
      return (this.can(TsxassAbility.CAN_VIEW_EMPLOYEES_ANALYTICS) && this.$route.query?.userId)
        || this.user?.userId || undefined;
    },
    hasEnablePdfDownloadingLicense(): boolean {
      return this.licenses.some((name: string) => name === LicenseType.ENABLE_PDF_DOWNLOADING);
    },
    has360DevelopmentPlanLicense() {
      return this.licenses.some((name: string) => name === LicenseType.TSXASS);
    },
    has360AtonLicense() {
      return this.licenses.some((name: string) => name === LicenseType.ATON_360);
    },
  },

  mounted() {
    this.loadTabs();
  },

  methods: {
    getTabPath(tabName: string): string {
      return this.$router.resolve({
        name: this.$route.name!,
        query: {
          ...this.$route.query,
          tab: tabName,
        },
      }).href;
    },
    async loadTabs() {
      try {
        this.tabsLoading = true;
        const { data } = await tsxassApi.getV1AnalyticsSurveysSurveyIdTabs(this.surveyId, this.userId || undefined);
        this.visibleTabs = data?.tabs || [];
      } catch (err) {
        errorHandler(err);
      } finally {
        this.tabsLoading = false;
      }
    },
  },
});
